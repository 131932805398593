export default [
  {
    header: 'Profissional',
  },

  
  {
    title: 'Pacientes',
    icon: 'UserIcon',
    children: [
      {
        title: 'Adicionar Paciente',
        route: 'adicionarPaciente',
      },
      {
        title: 'Listar Paciente',
        route: 'pacientes',
      },
      
    ],
  },
  // {
  //   title: 'Agenda',
  //   icon: 'CalendarIcon',
  //   route: 'agenda',
  // },
  {
    title: 'Consultas',
    icon: 'EditIcon',
    children: [
      {
        title: 'Marcar Consulta',
        route: 'novaConsulta',
      },
      {
        title: 'Agenda',
        route: 'agenda',
      },
    ],
  },
  {
    title: 'Jogos',
    icon: 'GridIcon',
    route: 'jogos',
  },
  // {
  //   title: 'Relatórios',
  //   icon: 'FileTextIcon',
  //   route: 'relatorios',
  // },
]
